var domain = document.location.protocol + '//' + document.location.hostname;

$(function(){

	$('.menu-trigger').on('click', function(e) {
		if($(this).hasClass('open')) {
			console.log('close');
			$(this).removeClass('open');
			$(this).nextAll('li').hide();
		} else {
			console.log('open');
			$(this).addClass('open');
			$(this).nextAll('li').show();
		}
	});

  // website exit
  $.expr[':'].external = function (a) {
    return a.href.indexOf('http') == 0 && a.href.indexOf(domain) != 0;
  };
  $('a:external').each(function(){
    $(this).attr('target','_blank');
  });

	/* left/right placement of tooltips */
	// var leftPosContent = $('.content').offset().left;
	// var widthContent   = $('.content').width();
	// $('.hint--bottom').each(function(){
	//   var leftPos = $(this).offset().left;
	//   if (widthContent / 2 < leftPos - leftPosContent) $(this).addClass('left');
	// });

	/* collapse and popup large tables */
	$('.table-zoom')
	.each(function(){
	  var minMargin   = 250;
	  var zoombutton  = $(this);
	  var table       = zoombutton.parent();
	  var tableHeight = table.height();
	  var positionTop = tableHeight > 600 ? minMargin : tableHeight / 2;

	  zoombutton.css('top',positionTop+'px');

	  if (tableHeight > 600) {
   	  var tableTop = table.offset().top;
      $(window).scroll(function(){
        var tableScrollPos = $(this).scrollTop() - tableTop;
   	    if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
     	    positionTop = minMargin + tableScrollPos;
     	    zoombutton.css('top',positionTop+'px');
   	    }
   	  });
   	}
	})
	.click(function(){
	  $(this).parent().addClass('popup');
	})
	;

	$('.table-close').click(function(){
	  $(this).parent().removeClass('popup');
	});

	/* fix left menu on page scroll */
  $(window).scroll(function(){
    var navHeight  = $('.sidebar-left').height();
    var pageHeight = $('.content').height();
    var maxMT      = pageHeight - navHeight - 80;
    if ($(window).height() > navHeight + 20 && pageHeight > navHeight && pageHeight > 1000) {
      var scrollPos = $(this).scrollTop();
      var margintop = scrollPos > 240 ? scrollPos - 240 : 0;
      margintop     = margintop > maxMT ? maxMT : margintop;
      $('.sidebar-left').css('margin-top',margintop);
    }
  });


  $('div.chart').each(function(){ 
    var baseHeight = $(this).height();
    $(this).css('height',baseHeight * 1.33);
  });
  

});

